import { render, staticRenderFns } from "./step2.vue?vue&type=template&id=134de0c0&"
import script from "./step2.vue?vue&type=script&lang=js&"
export * from "./step2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports